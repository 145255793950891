/** @define flex; weak */
.flex {
  display: flex;
}
.flex--1 {
  flex: 1;
}
.flex--dircol {
  flex-direction: column;
}
.flex--dirrow {
  flex-direction: row;
}
.flex--wrapwr {
  flex-wrap: wrap;
}
.flex--wrapnowr {
  flex-wrap: nowrap;
}
.flex--justcenter {
  justify-content: center;
}
.flex--justspacebet {
  justify-content: space-between;
}
.flex--justspacearo {
  justify-content: space-around;
}
.flex--justflend {
  justify-content: flex-end;
}
.flex--justflstart {
  justify-content: flex-start;
}
.flex--alignitflstart {
  align-items: flex-start;
}
.flex--alignitflend {
  align-items: flex-end;
}
.flex--alignitcenter {
  align-items: center;
}
.flex--alignconflstart {
  align-content: flex-start;
}
.flex--alignconflend {
  align-content: flex-end;
}
.flex--alignconcenter {
  align-content: center;
}
.flex--alignconspacebet {
  align-content: space-between;
}
.flex--alignconspacearo {
  align-content: space-around;
}
.flex__alignselfstart {
  align-self: flex-start;
}
.flex__alignselfend {
  align-self: flex-end;
}
.flex__alignselfcenter {
  align-self: center;
}
.flex__grow1 {
  flex-grow: 1;
}
/*# sourceMappingURL=css/flexbox.css.map */